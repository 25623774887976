import { ENV as ENVType, APP as APPType, FieldTypes } from '@/types/types';
import quickSignerLogo from '../assets/logo-quicksigner.png';
import eSemneazaLogo from '../assets/logo-esemneaza.svg';
import { Extensions } from '@/utils/extToMimeType';

export const APP = process.env.REACT_APP_APP as APPType;
export const ENV = process.env.REACT_APP_ENV as ENVType;

export const STRIPE_PUB_KEY = process.env.REACT_APP_STRIPE_PUB_KEY as string;

// export const ROPROD_GA_ID = 'G-1HP5071VKV';

// export const initialFieldSize = {
//     [FieldTypes.SIGNATURE]: {
//         width: 150,
//         height: 70,
//     },
//     [FieldTypes.TEXT]: {
//         width: 150,
//         height: 30,
//     },
//     [FieldTypes.STAMP]: {
//         width: 120,
//         height: 80,
//     },
// };

export const recipientColors = [
    '#FDDCAB5C',
    '#D9D6FE5C',
    '#FECDD65C',
    '#C7D7FE5C',
    '#FCCEEE5C',
    '#B9E6FE5C',
    '#D5D9EB5C',
    '#A6F4C55C',
];

export const recipientBorderColors = [
    '#FDDCAB',
    '#D9D6FE',
    '#FECDD6',
    '#C7D7FE',
    '#FCCEEE',
    '#B9E6FE',
    '#D5D9EB',
    '#A6F4C5',
];

export const FILL_TEXT_HEIGHT_RATIO = window.innerWidth < 1025 ? 0.5 : 0.75;

export const FILE_ATTACH_ALLOWED_EXTS: Extensions[] = [
    'jpg',
    'jpeg',
    'png',
    'doc',
    'docx',
    'pdf',
    'txt',
    'odt',
    'xls',
    'ppt',
    'pptx',
    'zip',
];

export const PLAN_TO_PUBLIC_NAME = {
    FREE: 'Free',
    PAID: 'Business',
    PRO: 'Pro',
};

export const CONFIG = {
    APP_NAME: {
        ESEMNEAZA: 'eSemneaza.ro',
        QUICKSIGNER: 'QuickSigner.com',
    },
    DEFAULT_LANGUAGE: {
        ESEMNEAZA: 'ro',
        QUICKSIGNER: 'en',
    },
    WEB_SITE_URL: {
        ESEMNEAZA: 'https://www.esemneaza.ro',
        QUICKSIGNER: 'https://www.quicksigner.com',
    },
    TOS_URL: {
        ESEMNEAZA: 'https://www.esemneaza.ro/termeni-si-conditii/',
        QUICKSIGNER: 'https://www.quicksigner.com/terms-and-conditions/',
    },
    API_DOC_URL: {
        ESEMNEAZA: 'https://esemneaza.stoplight.io',
        QUICKSIGNER: 'https://quicksigner.stoplight.io',
    },
    PRICES_URL: {
        ESEMNEAZA: 'preturi',
        QUICKSIGNER: 'prices',
    },
    APP_DOMAIN: {
        ESEMNEAZA: 'app.esemneaza.ro',
        QUICKSIGNER: 'app.quicksigner.com',
    },
    MANIFEST: {
        ESEMNEAZA: 'https://app.esemneaza.ro/manifest-esemneaza.json',
        QUICKSIGNER: 'https://app.quicksigner.com/manifest-quicksigner.json',
    },
    LOGO: {
        ESEMNEAZA: eSemneazaLogo,
        QUICKSIGNER: quickSignerLogo,
    },
    LOGO_HEIGHT: {
        ESEMNEAZA: '25px',
        QUICKSIGNER: '35px',
    },
    META_DESCRIPTION: {
        ESEMNEAZA:
            'Modernizează și accelerează semnarea documentelor. Gratuit. Cu valoare legală. Fără token.',
        QUICKSIGNER:
            'Modernize and accelerate the management and signing of documents.',
    },
    CURRENCY: {
        ESEMNEAZA: 'RON',
        QUICKSIGNER: 'USD',
    },
    DATE_FORMAT: {
        ESEMNEAZA: 'DD-MM-YYYY',
        QUICKSIGNER: 'MM-DD-YYYY',
    },
    PHONE_NUMBER_PLACEHOLDER: {
        ESEMNEAZA: '07********',
        QUICKSIGNER: '+*********',
    },
    GTM_ID: {
        ESEMNEAZA: 'GTM-57VFS9QW',
        QUICKSIGNER: 'GTM-T3JSF5X8',
    },
    CONTACT_EMAIL: {
        ESEMNEAZA: 'info@esemneaza.ro',
        QUICKSIGNER: 'contact@quicksigner.com',
    },
};

export const RECAPTCHA_CONTAINER_ID = 'recaptcha-container';

export const APP_NAME = CONFIG.APP_NAME[APP];
export const WEB_SITE_URL = CONFIG.WEB_SITE_URL[APP];
export const PRICES_URL = CONFIG.PRICES_URL[APP];
export const APP_DOMAIN = CONFIG.APP_DOMAIN[APP];
export const MANIFEST = CONFIG.MANIFEST[APP];
export const LOGO = CONFIG.LOGO[APP];
export const META_DESCRIPTION = CONFIG.META_DESCRIPTION[APP];
export const DEFAULT_LANGUAGE = CONFIG.DEFAULT_LANGUAGE[APP];
export const LOGO_HEIGHT = CONFIG.LOGO_HEIGHT[APP];
export const CURRENCY = CONFIG.CURRENCY[APP];
export const DATE_FORMAT = CONFIG.DATE_FORMAT[APP];
export const PHONE_NUMBER_PLACEHOLDER = CONFIG.PHONE_NUMBER_PLACEHOLDER[APP];
export const API_BASE_URL =
    ENV === 'LOCAL' ? 'http://localhost:8080/fe-api' : '/fe-api';
export const DEFAULT_DOC_NAME = 'document.pdf';
export const TOS_URL = CONFIG.TOS_URL[APP];
export const API_DOC_URL = CONFIG.API_DOC_URL[APP];
export const GTM_ID = CONFIG.GTM_ID[APP];
export const CONTACT_EMAIL = CONFIG.CONTACT_EMAIL[APP];
