import { ReactComponent } from '@/assets/icons/download.svg';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

export interface UploadIconProps {
    color?: string;
    height?: number;
    style?: CSSProperties;
    className?: string;
    onClick?: () => void;
}

const Icon = ({
    color = '#757A7B',
    height = 20,
    style,
    className,
    onClick,
}: UploadIconProps) => {
    const { t } = useTranslation(['buttons']);
    return (
        <ReactComponent
            height={height}
            stroke={color}
            style={{ minWidth: height, ...style }}
            className={className}
            onClick={onClick}
            title={t('download')}
        />
    );
};
export default Icon;
