/* eslint-disable no-extend-native */
const arrayAtPolyfill = () => {
    if (!Array.prototype.at) {
        Array.prototype.at = function (index) {
            if (index >= 0) {
                return this[index];
            } else {
                return this[this.length + index];
            }
        };
    }
};

export default arrayAtPolyfill;
