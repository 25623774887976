import { BarLoader } from 'react-spinners';

export interface LoadingProps {}

const Loading = ({}: LoadingProps) => {
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <BarLoader width={'100%'} color="#0076cc" />
        </div>
    );
};
export default Loading;
