import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRequestContext } from './Request';
import orderBy from 'lodash.orderby';

interface GoToField {
    id: string | number | null;
}

type MarkerPosition = {
    pageNum: number;
    y: number;
    x: number;
} | null;

export interface MainContextValue {
    goToField: GoToField;
    goToNextField: () => void;
    markerPosition: MarkerPosition;
    reset: () => void;
    nextFieldOrder: number;
}

// @ts-expect-error
const MainContext = React.createContext<MainContextValue>();

interface ProviderProps {
    children: React.ReactNode;
}

export const SignProvider = ({ children }: ProviderProps) => {
    const { fields } = useRequestContext();
    const [goToField, setGoToField] = useState<GoToField>({
        id: null,
    });
    const [markerPosition, setMarkerPosition] = useState<MarkerPosition>(null);
    const [nextFieldOrder, setNextFieldOrder] = useState(1);

    useEffect(() => {
        const field = fields.find(({ id }) => id === goToField.id);

        if (field) {
            const { pageNum, y, height, x } = field;
            setMarkerPosition({ pageNum, y: y + height / 2, x });
        }
    }, [fields, goToField]);

    const reset = useCallback(() => {
        setGoToField({ id: null });
        setMarkerPosition(null);
        setNextFieldOrder(1);
    }, []);

    useEffect(() => {
        reset();
    }, [fields.length, reset]);

    const orderedFields = useMemo(
        () => orderBy(fields, ['pageNum', 'y', 'x']),
        [fields]
    );

    const goToNextField = useCallback(() => {
        if (orderedFields.length === 0) {
            return;
        }

        const goToFieldIndex = (index: number) => {
            const { id, pageNum, y, height, x } = orderedFields[index];
            setGoToField({ id });
            setMarkerPosition({ pageNum, y: y + height / 2, x });
            setNextFieldOrder(index < orderedFields.length - 1 ? index + 2 : 1);
        };

        const prevFieldIndex = orderedFields.findIndex(
            (field) => field.id === goToField?.id
        );

        const isFirstRound = prevFieldIndex === -1;

        const isLastField = prevFieldIndex === orderedFields.length - 1;

        if (isFirstRound || isLastField) {
            goToFieldIndex(0);
        } else {
            goToFieldIndex(prevFieldIndex + 1);
        }
    }, [goToField, orderedFields]);

    const value = {
        goToField,
        goToNextField,
        markerPosition,
        reset,
        nextFieldOrder,
    };

    return (
        <MainContext.Provider value={value}>{children}</MainContext.Provider>
    );
};

export const useSignContext = () => {
    const context = React.useContext(MainContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }
    return context;
};
