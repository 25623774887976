import { ReactComponent } from '@/assets/icons/tooltip.svg';
import { CSSProperties } from 'react';

export interface UploadIconProps {
    color?: string;
    height?: number;
    style?: CSSProperties;
    className?: string;
    onClick?: () => void;
    id?: string;
    content?: string;
}


const Icon = ({
    color = '#0076CC',
    height = 16,
    style,
    className,
    onClick,
    id,
    content,
}: UploadIconProps) => {
    return (
        <ReactComponent
            data-tooltip-id={id}
            data-tooltip-content={content}
            data-tooltip-place="top"
            data-tooltip-variant="dark"
            data-tooltip-delay-show={100}
            height={height}
            // minWidth={`${24}px`}
            stroke={color}
            style={style}
            className={className}
            onClick={onClick}
        />
    );
};
export default Icon;
