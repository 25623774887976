import { ReactComponent } from '@/assets/icons/signature.svg';
import { CSSProperties } from 'react';

export interface UploadIconProps {
    color?: string;
    height?: number;
    style?: CSSProperties;
    className?: string;
    onClick?: () => void;
}

const Icon = ({
    color = '#757A7B',
    height = 25,
    style,
    className,
    onClick,
}: UploadIconProps) => {
    return (
        <ReactComponent
            height={height}
            stroke={color}
            fill={color}
            style={style}
            className={className}
            onClick={onClick}
        />
    );
};
export default Icon;
