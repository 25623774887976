import { ReactComponent } from '@/assets/icons/mail.svg';
import { CSSProperties } from 'react';

export interface IconProps {
    color?: string;
    height?: number;
    style?: CSSProperties;
    className?: string;
    onClick?: () => void;
    strokeWidth?: number;
}

const Icon = ({
    color = '#757A7B',
    height = 20,
    style,
    className,
    onClick,
    strokeWidth = 1.3,
}: IconProps) => {
    return (
        <ReactComponent
            height={height}
            stroke={color}
            strokeWidth={strokeWidth}
            style={{
                minWidth: `${height}px`,
                cursor: onClick ? 'pointer' : 'default',
                ...style,
            }}
            className={className}
            onClick={onClick}
            title="Email"
        />
    );
};
export default Icon;
