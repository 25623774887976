import { ReactNode } from 'react';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { usePreview } from 'react-dnd-preview';

import { DnDTypes, FieldTypes } from '@/types/types';
import useIsTouchScreen from '@/hooks/useIsTouchScreen';
import { SOURCE_FIELD_HEIGHT, SOURCE_FIELD_WIDTH } from '../Fields/SourceField';
import { useFieldsLables } from '@/hooks/useFieldsLabels';

export interface DnDProviderProps {
    children: ReactNode;
}

const TouchPreview = () => {
    const fieldsLabels = useFieldsLables();
    const preview = usePreview();
    if (!preview.display) {
        return null;
    }
    const { itemType, item, style }: any = preview;

    return (
        <div
            style={{
                ...style,
                backgroundColor: '#96969687',
                borderRadius: 6,
                width:
                    itemType === DnDTypes.SOURCE_FIELD
                        ? SOURCE_FIELD_WIDTH
                        : item.width,
                height:
                    itemType === DnDTypes.SOURCE_FIELD
                        ? SOURCE_FIELD_HEIGHT
                        : item.height,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {itemType === DnDTypes.SOURCE_FIELD && (
                <span>{fieldsLabels[item.type as FieldTypes]}</span>
            )}
        </div>
    );
};

const DnDProvider = ({ children }: DnDProviderProps) => {
    const isTouchScreen = useIsTouchScreen();
    return (
        <DndProvider
            backend={isTouchScreen ? TouchBackend : HTML5Backend}
            options={{ ignoreContextMenu: true }}
        >
            {children}
            {isTouchScreen && <TouchPreview />}
        </DndProvider>
    );
};
export default DnDProvider;
