import {
    APP,
    DEFAULT_LANGUAGE,
    ENV,
    MANIFEST,
    META_DESCRIPTION,
} from './constants';

const setManifest = () => {
    // const manifestLink = document.createElement('link');
    // manifestLink.setAttribute('rel', 'manifest');
    // manifestLink.setAttribute('href', MANIFEST);
    // document.head.appendChild(manifestLink);

    const manifestLink = document.querySelector('#manifest-link-el');
    manifestLink?.setAttribute('href', MANIFEST);
};

const setLanguage = () => {
    document.documentElement.setAttribute('lang', DEFAULT_LANGUAGE);
};

const setDescription = () => {
    const metaDescription = document.createElement('meta');
    metaDescription.setAttribute('name', 'description');
    metaDescription.setAttribute('content', META_DESCRIPTION);
    document.head.appendChild(metaDescription);
};

const setFavicon = () => {
    const faviconSuffix = APP === 'QUICKSIGNER' ? '-qs' : '';
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = `${process.env.PUBLIC_URL}/favicon${faviconSuffix}.png`;
    document.getElementsByTagName('head')[0].appendChild(link);
};

export const init = () => {
    setLanguage();
    setDescription();
    setFavicon();

    if (ENV !== 'LOCAL') {
        setManifest();
    }
};
