import { ReactComponent as NewRequest } from '@/assets/icons/new-request.svg';

export interface NewRequestIconProps {
    color?: string;
    height?: number;
}

const NewRequestIcon = ({
    color = 'black',
    height = 20,
}: NewRequestIconProps) => {
    return <NewRequest height={height} stroke={color} />;
};
export default NewRequestIcon;
