import React from 'react';
import ReactDOM from 'react-dom/client';
import './ress.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { translations } from './constants/translations';

import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';

import { AuthProvider } from './contexts/Auth';
import { MainProvider } from './contexts/Main';
import { RequestProvider } from './contexts/Request';

import 'react-tooltip/dist/react-tooltip.css';
import DnDProvider from './components/DnD/DnDProvider';
import { ENV, DEFAULT_LANGUAGE, APP, GTM_ID } from './constants';

import FallbackError from './components/common/FallbackError';
import { SignProvider } from './contexts/Sign';

import offscreenCanvasPolyfill from '../src/utils/offscreenCanvasPolyfill';
import replaceAllPolyfill from '../src/utils/replaceAllPolyfill';
import arrayAtPolyfill from './utils/arrayAtPolyfill';
import { init } from './init';

offscreenCanvasPolyfill();
replaceAllPolyfill();
arrayAtPolyfill();

init();

if (ENV === 'PROD') {
    TagManager.initialize({
        gtmId: GTM_ID,
    });
}

if (ENV === 'PROD') {
    Sentry.init({
        dsn: 'https://4846af26e29e4e319740b6e1e39d205f@o4505562883489792.ingest.sentry.io/4505562884800512',
        integrations: [
            // new Sentry.BrowserTracing({
            //     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            //     tracePropagationTargets: ['localhost', 'https:yourserver.io/api/'],
            // }),
            new Sentry.Replay(),
        ],
        environment: 'production',
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: translations,
        lng: DEFAULT_LANGUAGE, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    // <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<FallbackError />}>
        <BrowserRouter>
            <AuthProvider>
                <RequestProvider>
                    <MainProvider>
                        <SignProvider>
                            <DnDProvider>
                                <App />
                            </DnDProvider>
                        </SignProvider>
                    </MainProvider>
                </RequestProvider>
            </AuthProvider>
        </BrowserRouter>
    </Sentry.ErrorBoundary>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
