import {
    BulkRequest,
    BulkRequestStatus,
    CustomerType,
    Field,
    FrontEndSignature,
    Package,
    Request,
    RequestStatus,
    SigStatus,
    SignData,
    SubscriptionType,
    User,
    Webhook,
    Plan,
    Seat,
    SeatStatus,
    Team,
    RequestSource,
    AppSubsCount,
} from '@/types/types';
import { api } from './client';
import axios from 'axios';
import { wait } from '@testing-library/user-event/dist/utils';
import { Dispatch, SetStateAction } from 'react';
import saveAs from 'file-saver';
import { API_BASE_URL } from '@/constants';
import { extractFileNameFromHeader } from '@/utils/text';

export const createUser = ({
    name,
    isApiDev = false,
}: {
    name: string;
    isApiDev?: boolean;
}) => {
    // user data is decoded by backend from the user token
    return api.post<User>('/users', {
        name,
        isApiDev,
    });
};

export const getUser = () => {
    // user id is decoded by backend from the user token
    return api.get<User>(`/users`);
};

export const updateUser = (user: Partial<User>) => {
    // user id is decoded by backend from the user token
    return api.put<User>('/users', user);
};

export const deleteUser = () => {
    return api.delete(`/users`);
};

export interface CreateRequestParams {
    docUrl: string;
    recipients: string[];
    signInOrder: boolean;
    fields: Field[];
    prefillTexts: Field[];
    emailMessage: string;
    tags: string[];
    asTemplate?: boolean;
    fromTemplate: boolean;
}

export const createRequest = (requestData: CreateRequestParams) => {
    return api.post<Request>('/requests', requestData);
};

export const updateTemplate = (id: string, data: CreateRequestParams) => {
    return api.put<Request>(`/templates/${id}`, data);
};

export const getSignDataNoAuth = (requestId: string, token: string) => {
    return api.get<SignData>(`requests/${requestId}/sign/noauth`, {
        params: {
            token,
        },
    });
};

export const getSignData = (requestId: string) => {
    return api.get<SignData>(`requests/${requestId}/sign`);
};

export const signNoAuth = (
    requestId: string,
    sigs: FrontEndSignature[],
    token: string,
    signerIpAddress?: string
) => {
    return api.put<any>(
        `requests/${requestId}/sign/noauth`,
        {
            sigs,
            ipAddress: signerIpAddress,
            userAgent: window.navigator.userAgent,
        },
        {
            params: {
                token,
            },
        }
    );
};

export const sign = ({
    requestId,
    sigs,
    signerIpAddress,
    recipientId,
}: {
    requestId: string;
    sigs: FrontEndSignature[];
    signerIpAddress?: string;
    recipientId: string;
}) => {
    return api.put<any>(`requests/${requestId}/sign`, {
        sigs,
        ipAddress: signerIpAddress,
        userAgent: window.navigator.userAgent,
        recipientId,
    });
};

export const getSentRequests = ({
    cursor,
    limit = 10,
    from,
    to,
    searchQuery,
    status,
    recipients,
    tags,
}: {
    cursor?: string;
    limit?: number;
    from?: Date | null;
    to?: Date | null;
    searchQuery?: string | null;
    status?: RequestStatus[];
    recipients?: string[];
    tags?: string[];
}) => {
    return api.get<Request[]>('/requests/sent', {
        params: {
            cursor,
            limit,
            from,
            to,
            searchQuery: searchQuery || null,
            status: status?.length ? status.join(',') : null,
            recipients: recipients?.length ? recipients.join(',') : null,
            tags: tags?.length ? tags.join(',') : null,
        },
    });
};

export const getTemplates = ({
    cursor,
    limit = 10,
}: {
    cursor?: string;
    limit?: number;
}) => {
    return api.get<Request[]>('/requests/sent', {
        params: {
            cursor,
            limit,
            status: RequestStatus.TEMPLATE,
        },
    });
};

export const getReceivedRequests = ({
    cursor,
    limit = 10,
}: {
    cursor?: string;
    limit?: number;
}) => {
    return api.get<Request[]>('/requests/received', {
        params: {
            cursor,
            limit,
        },
    });
};

export const getTempSignedDocUrl = (
    requestId: string,
    { isBulk }: { isBulk?: boolean } = {}
) => {
    return api.get<{ docUrl: string }>(`/request/${requestId}/docurl`, {
        params: { isBulk },
    });
};

export const getRequestStatus = (requestId: string) => {
    return api.get<{ status: RequestStatus }>(`/request/${requestId}/status`);
};

export const getRequestById = (requestId: string) => {
    return api.get<Request>(`/request/${requestId}`);
};

export const getReceivedRequestById = (requestId: string) => {
    return api.get<Request>(`/request/${requestId}/received`);
};

export const getRecipientSigStatus = (recipientId: string) => {
    return api.get<{ sigStatus: SigStatus }>(
        `/recipients/${recipientId}/status`
    );
};

// export const waitForRequestCompletedStatus = (
//     requestId: string
// ): Promise<void> => {
//     const MAX_RETRIES = 100;
//     const MAX_DURATION = 10 * 60 * 1000; // 10 min

//     let retriesCount = 0;
//     let startTime = Date.now();

//     let waitTime = 2000;

//     return new Promise((resolve, reject) => {
//         const getStatus = async () => {
//             try {
//                 const duration = Date.now() - startTime;
//                 retriesCount++;

//                 const { data } = await getRequestStatus(requestId);

//                 if (retriesCount > MAX_RETRIES || duration > MAX_DURATION) {
//                     reject();
//                     return;
//                 }

//                 if (data.status === RequestStatus.COMPLETED) {
//                     resolve();
//                     return;
//                 }

//                 await wait(waitTime);
//                 waitTime += 1000;
//                 getStatus();
//             } catch (error) {
//                 reject(error);
//             }
//         };
//         getStatus();
//     });
// };

export const cancelRequest = (requestId: string) => {
    return api.patch(`/requests/${requestId}`);
};

export const deleteRequest = (requestId: string) => {
    return api.delete(`/requests/${requestId}`);
};

export const convertToPdf = (fileName: string) => {
    return api.put(`/conversions/${fileName}`);
};

export const mergeFiles = (fileName_1: string, fileName_2: string) => {
    return api.put(`/files/merge`, { fileName_1, fileName_2 });
};

export const getIp = () => {
    return axios.get<{ ip: string }>('https://api.ipify.org?format=json', {
        timeout: 2500,
    });
};

export const verifyEmail = (token: string) => {
    return api.put(
        `/users/verifyEmail`,
        {},
        {
            params: {
                token,
            },
        }
    );
};

//super apis

export const getRequests = ({
    cursor,
    limit = 10,
    from,
    to,
    searchQuery,
    status,
    recipients,
    tags,
    source,
}: any) => {
    return api.get<Request[]>('/super/requests', {
        params: {
            cursor,
            limit,
            from,
            to,
            searchQuery,
            status: status?.length ? status.join(',') : null,
            recipients: recipients?.length ? recipients.join(',') : null,
            tags: tags?.length ? tags.join(',') : null,
            source,
        },
    });
};

export const getTempSignedDocUrl_super = (requestId: string) => {
    return api.get<{ docUrl: string }>(`/super/docurl/${requestId}`);
};

export interface GetStatsResponse {
    users: number;
    deletedUsers: number;
    senders: number;
    requests: number;
    inProgress: number;
    completed: number;
    canceled: number;
    bulkCount: number;
    bulkRequestsCount: number;
    apiRequestsCount: number;
    recipientsPerRequestAvg: string;
    fieldsPerRecipientAvg: string;
    apiDevAccountsCount: number;
    apiDevRequestsCount: number;
}

export const super_getStats = () => {
    return api.get<GetStatsResponse>(`/super/stats`);
};

export interface WeeklyResponse {
    total: number;
    weekly: Weekly[];
}

export interface Weekly {
    count: number;
    week: number;
}

export const super_getWeeklyRequestsCount = ({
    from,
    source,
}: {
    from?: Date;
    source: RequestSource;
}) => {
    return api.get<WeeklyResponse>(`/super/weeklyRequestsCount`, {
        params: { from, source },
    });
};

export const super_getWeeklyNewUsersCount = (from?: Date) => {
    return api.get<{ weekly: Weekly[] }>(`/super/weeklyNewUsersCount`, {
        params: { from },
    });
};

export const super_getWeeklySubscriptions = (from: Date) => {
    return api.get<{ subsCount: AppSubsCount[] }>(`/super/subsCount`, {
        params: { from },
    });
};

export type DailyResponse = Daily[];
export interface Daily {
    count: number;
    day: string;
}

export const super_getDailyRequestsCount = (
    from: Date,
    source: RequestSource
) => {
    return api.get<DailyResponse>(`/super/dailyRequestsCount`, {
        params: { from, source },
    });
};

export interface DailyNewUsersStats {
    users: UserStats[];
    count: number;
    noRequestsCount: number;
    selfOnlyCount: number;
    sentRequestsCount: number;
    date: string;
}

export interface UserStats {
    name: string;
    email: string;
    requests: number;
}

export const super_getDailyNewUsersCount = (from?: Date) => {
    return api.get<DailyNewUsersStats[]>(`/super/dailyNewUsersCount`, {
        params: { from },
    });
};

export interface UserRequestsCount {
    id: string;
    createdAt: string;
    name: string;
    email: string;
    emailVerified: boolean;
    plan: string;
    converted: boolean;
    requestsCount: number;
    completedCount: number;
    inProgressCount: number;
    templateCount: number;
    canceledCount: number;
    deletedCount: number;
    lastRequestDate: string;
    onlySelfSigned: boolean;
    isRecipient: boolean;
    // uniqueRecipients: string[];
    uniqueRecipientsCount: number;
}

export const super_getUsersRequestsCount = () => {
    return api.get<UserRequestsCount[]>(`/super/usersRequestsCount`);
};

export const super_createApiToken = (usersEmails: string[]) => {
    return api.post(`/super/apiPackages`, { usersEmails });
};

export interface PackageResponse extends Package {
    users: Partial<User>[];
    consumption: number;
    consumptionPrecentage: string;
}

export const super_getApiPackages = () => {
    return api.get<PackageResponse[]>(`/super/apiPackages`);
};

export const script_migrateRecipientsV2 = () => {
    return api.put(`/scripts/migrateRecipientsV2`);
};

export const script_migratePackages = () => {
    return api.put(`/scripts/migratePackages`);
};

export const script_firstScript = () => {
    return api.put(`/scripts/firstScript`, undefined, { timeout: 10 * 60000 });
};

export const getUserTags = () => {
    return api.get<string[]>(`/users/tags`);
};

export interface FiltersData {
    tags: string[];
    recipients: string[];
}

export const getRequestsFilters = () => {
    return api.get<FiltersData>(`/requests/filters`);
};

export const getTemplateById = (id: string) => {
    return api.get<Request>(`/templates/${id}`);
};

export const waitForCustomerType = (
    customerType: CustomerType
): Promise<User> => {
    const MAX_RETRIES = 40;
    const MAX_DURATION = 40 * 1000; // 40s

    let retriesCount = 0;
    let startTime = Date.now();

    return new Promise((resolve, reject) => {
        const getStatus = async () => {
            try {
                const duration = Date.now() - startTime;
                retriesCount++;

                const { data } = await getUser();

                if (retriesCount > MAX_RETRIES || duration > MAX_DURATION) {
                    reject(new Error('Plan activation error.'));
                    return;
                }

                if (data.customerType === customerType) {
                    resolve(data);
                    return;
                }

                await wait(1000);
                getStatus();
            } catch (error) {
                reject(error);
            }
        };
        getStatus();
    });
};

export const syncSubscriptionState = (setUser: any) => {
    const MAX_RETRIES = 4;
    const WAIT_TIME = 1000; // 1s
    const MAX_DURATION = MAX_RETRIES * WAIT_TIME + 3000;

    let retriesCount = 0;
    let startTime = Date.now();

    const getStatus = async () => {
        try {
            const duration = Date.now() - startTime;
            retriesCount++;

            const { data } = await getUser();

            if (retriesCount > MAX_RETRIES || duration > MAX_DURATION) {
                return;
            }

            setUser((prev: User): User => {
                return {
                    ...prev,
                    customerType: data.customerType,
                    paidUntil: data.paidUntil,
                    plan: data.plan,
                    numOfSeats: data.numOfSeats,
                };
            });

            await wait(WAIT_TIME);
            getStatus();
        } catch (error) {
            return;
        }
    };
    getStatus();
};

//payments
export const getCheckoutSession = (type: SubscriptionType) => {
    return api.get<{ url: string }>('/payments/checkout/session', {
        params: {
            type,
        },
    });
};

export const getCheckoutSessionForPriceId = (priceId: string) => {
    return api.get<{ url: string }>(`/prices/${priceId}/checkout`);
};

export type ModifySubscriptionType = 'cancel' | 'edit-payment' | 'general';

export const getPortalSession = ({
    type,
}: {
    type: ModifySubscriptionType;
}) => {
    return api.get<{ url: string }>('/payments/portal/session', {
        params: {
            type,
        },
    });
};

export interface PlanData {
    priceId: string;
    name: string;
    amount: number;
    trialDays: number;
    interval: 'month' | 'year';
    currency: 'ron' | 'usd';
}

export interface SeatInvite {
    id: string;
    invitedBy: string;
    plan: Plan;
    status: SeatStatus;
}

export interface Plans {
    anual: number;
    monthly: number;
    trialDays?: number;
    plans: PlanData[];
    seat?: SeatInvite;
}

export const getPlans = () => {
    return api.get<Plans>('/payments/plans');
};

export const getSignUrlForShortUrlId = (id: string) => {
    return api.get<{ signUrl: string }>(`/shorturls/${id}`);
};

export const getDocumentUrlForShortUrlId = (id: string) => {
    return api.get<{ downloadDocUrl: string }>(`/shorturls/${id}/documentUrl`);
};

export const getPackageConsumption = (packageId: string) => {
    return api.get<{ requestsCount: number }>(`/requests/${packageId}/count`);
};

export const getWebhooks = () => {
    return api.get<{
        webhooks: Webhook[];
        events: string[];
        eventsSampleData: { [key: string]: object };
    }>(`/webhooks`);
};

export const saveApiConfig = (webhooks: Partial<Webhook>[]) => {
    return api.put<{
        webhooks: Webhook[];
    }>(`/api-config`, { webhooks });
};

export const getVideoIdSession = (token: string) => {
    return api.get(`/videoId/session?token=${token}`);
};

export const waitForVideoIdStatus = (
    requestId: string,
    token: string
): Promise<any> => {
    const MAX_RETRIES = 40;
    const MAX_DURATION = 40 * 1000; // 40s

    let retriesCount = 0;
    let startTime = Date.now();

    return new Promise((resolve, reject) => {
        const getStatus = async () => {
            try {
                const duration = Date.now() - startTime;
                retriesCount++;

                const { data } = await getSignDataNoAuth(requestId, token);

                if (retriesCount > MAX_RETRIES || duration > MAX_DURATION) {
                    reject(new Error('Video id status error.'));
                    return;
                }

                if (data.videoIdStatus !== 'PENDING') {
                    resolve(data.videoIdStatus);
                    return;
                }

                await wait(1000);
                getStatus();
            } catch (error) {
                reject(error);
            }
        };
        getStatus();
    });
};

export const saveAdditionalFile = (requestId: string, fileUrl: string) => {
    return api.put('/requests/additionalFiles', { requestId, fileUrl });
};

export const deleteAdditionalFile = (requestId: string, fileId: string) => {
    return api.delete(`/requests/${requestId}/additionalFiles/${fileId}`);
};

export const remindRecipient = (recipientId: string) => {
    return api.put(`/recipients/${recipientId}/remind`);
};

export const canRemindRecipientOrThrow = (recipientId: string) => {
    return api.get(`/recipients/${recipientId}/canRemind`);
};

export const createBulkRequest = (data: any) => {
    return api.post(`/bulkRequests`, data);
};

export const getBulkRequests = ({
    cursor,
    limit,
}: {
    cursor?: string;
    limit?: number;
}) => {
    return api.get<BulkRequest[]>('/bulkRequests', {
        params: {
            cursor,
            limit,
        },
    });
};

export const getBulkRequestById = (requestId: string) => {
    return api.get<BulkRequest>(`/bulkRequests/${requestId}`);
};

export const listenToBulkRequestUpdates = ({
    requestId,
    setUpdatedStatus,
    setUpdatedSentRequestsCount,
}: {
    requestId: string;
    setUpdatedStatus: Dispatch<SetStateAction<BulkRequestStatus | undefined>>;
    setUpdatedSentRequestsCount: Dispatch<SetStateAction<number | undefined>>;
}) => {
    let stopListening = false;

    const updateState = async () => {
        if (stopListening) return;
        try {
            const { data } = await getBulkRequestById(requestId);
            const { status, sentRequestsCount } = data;
            setUpdatedSentRequestsCount(sentRequestsCount);
            if (status !== 'SENT') {
                await wait(1000);
                updateState();
            } else {
                setUpdatedStatus('SENT');
            }
        } catch (error) {
            //do nothing?
        }
    };
    updateState();

    const stopListener = () => {
        stopListening = true;
    };

    return stopListener;
};

export const getFieldsFromTags = (fileName: string) => {
    return api.get<{ fields: Field[] }>(`/fields/${fileName}/fromTags`);
};

export const uploadFile = (
    file: File,
    progressUpdateFn?: (progress: number) => void
) => {
    return api.postForm<{ path: string; fileName: string }>('/files', [file], {
        onUploadProgress: (progressEvent) => {
            const progress = progressEvent.loaded / progressEvent.total;
            progressUpdateFn?.(progress);
        },
        timeout: 60000,
    });
};

export const uploadFileAttachment = ({
    file,
    recipientId,
    requestId,
    progressUpdateFn,
}: {
    file: File;
    recipientId: string;
    requestId: string;
    progressUpdateFn?: (progress: number) => void;
}) => {
    return api.postForm<{ path: string; fileName: string }>(
        `/recipients/${recipientId}/attachments`,
        [file],
        {
            params: {
                requestId,
            },
            onUploadProgress: (progressEvent) => {
                const progress = progressEvent.loaded / progressEvent.total;
                progressUpdateFn?.(progress);
            },
        }
    );
};

export const uploadFileAttachmentNoAuth = ({
    file,
    token,
    progressUpdateFn,
}: {
    file: File;
    token: string;
    progressUpdateFn?: (progress: number) => void;
}) => {
    return api.postForm<{ path: string; fileName: string }>(
        `/noAuth/recipients/attachments`,
        [file],
        {
            params: {
                token,
            },
            onUploadProgress: (progressEvent) => {
                const progress = progressEvent.loaded / progressEvent.total;
                progressUpdateFn?.(progress);
            },
        }
    );
};

export const noop = () => {
    return api.get('/noop');
};

export const refreshAuthTokenIfExpired = () => {
    return noop();
};

const fetchFileAsBlob = async (
    fileName: string,
    { templateId }: { templateId?: string } = {}
) => {
    await noop(); //refreshes the access token if necessary
    const { Authorization } = api.defaults.headers.common;

    const params = new URLSearchParams();
    if (templateId) {
        params.append('templateId', templateId);
    }

    try {
        const res = await fetch(
            `${API_BASE_URL}/files/${fileName}?${params.toString()}`,
            {
                headers: {
                    Authorization: Authorization as string,
                },
            }
        );

        if (res.ok) {
            const blob = await res.blob();
            return { blob, fileName };
        }

        throw new Error('File fetch error.');
    } catch (error) {
        throw new Error('File fetch error.');
    }
};

interface FetchOptions {
    isBulk?: boolean;
}

const fetchDocument = async (requestId: string, options?: FetchOptions) => {
    await noop(); //refreshes the access token if necessary
    const { Authorization } = api.defaults.headers.common;

    try {
        const res = await fetch(
            `${API_BASE_URL}/requests/${requestId}/document${
                options?.isBulk ? '?isBulk=true' : ''
            }`,
            {
                headers: {
                    Authorization: Authorization as string,
                },
            }
        );

        if (res.ok) {
            const contentDispositionHeader = res.headers.get(
                'Content-Disposition'
            );
            const fileName = extractFileNameFromHeader(
                contentDispositionHeader
            );
            const blob = await res.blob();
            return { blob, fileName };
        } else {
            throw new Error('File fetch error.');
        }
    } catch (error) {
        throw new Error('File fetch error.');
    }
};

interface NoAuthOptions {
    isCompleted?: boolean;
}

const fetchDocumentNoAuth = async (token: string, options?: NoAuthOptions) => {
    try {
        const res = await fetch(
            `${API_BASE_URL}/noAuth/documents${
                options?.isCompleted ? '/completed' : ''
            }?token=${token}`
        );

        if (res.ok) {
            const contentDispositionHeader = res.headers.get(
                'Content-Disposition'
            );
            const fileName = extractFileNameFromHeader(
                contentDispositionHeader
            );

            const blob = await res.blob();
            return { blob, fileName };
        } else {
            throw new Error('File fetch error.');
        }
    } catch (error) {
        throw new Error('File fetch error.');
    }
};

export const downloadDocument = async (
    requestId: string,
    options?: FetchOptions
) => {
    const { blob, fileName } = await fetchDocument(requestId, options);
    saveAs(blob, fileName);
};

export const getDocumentAsFile = async (requestId: string) => {
    const { blob, fileName } = await fetchDocument(requestId);
    return new File([blob], fileName);
};

export const getDocumentAsFileNoAuth = async (token: string) => {
    const { blob, fileName } = await fetchDocumentNoAuth(token);
    return new File([blob], fileName);
};

export const downloadCompletedDocumentNoAuth = async (token: string) => {
    const { blob, fileName } = await fetchDocumentNoAuth(token, {
        isCompleted: true,
    });
    saveAs(blob, fileName);
};

export const getFileAsFile = async (
    file: string,
    { templateId }: { templateId?: string } = {}
) => {
    const { blob, fileName } = await fetchFileAsBlob(file, { templateId });
    return new File([blob], fileName);
};

export const getCompletedDocumentNameNoAuth = (token: string) => {
    return api.get<{ docName: string }>('/noAuth/documents/completed/name', {
        params: { token },
    });
};

export const getRequestDetails = (requestId: string) => {
    return api.get<Partial<Request>>(`/requests/${requestId}/details`);
};

const fetchFileAttachment = async (id: string) => {
    await noop(); //refreshes the access token if necessary
    const { Authorization } = api.defaults.headers.common;

    try {
        const res = await fetch(`${API_BASE_URL}/requests/attachments/${id}`, {
            headers: {
                Authorization: Authorization as string,
            },
        });

        if (res.ok) {
            const contentDispositionHeader = res.headers.get(
                'Content-Disposition'
            );
            const fileName = extractFileNameFromHeader(
                contentDispositionHeader
            );
            const blob = await res.blob();
            return { blob, fileName };
        } else {
            throw new Error('File fetch error.');
        }
    } catch (error) {
        throw new Error('File fetch error.');
    }
};

export const downloadFileAttachment = async (id: string) => {
    const { blob, fileName } = await fetchFileAttachment(id);
    saveAs(blob, fileName);
};

export const enableApi = () => {
    return api.put('/users/enableApi');
};

export interface ApiPackageDataRes {
    consumption: number;
    startDate: string;
    endDate: string;
    users: string[];
    numOfRequests: number;
}

export const getApiPackageData = () => {
    return api.get<ApiPackageDataRes>('/users/apiPackageData');
};

export const resetPassword = (email: string) => {
    return api.put(`/users/${email}/resetPassword`);
};

export const getSeats = () => {
    return api.get<Seat[]>(`/seats`);
};

export const addSeat = (email: string) => {
    return api.put(`/seats/${email}`);
};

export const deleteSeat = (email: string) => {
    return api.delete(`/seats/${email}`);
};

export const sendSeatInviteResponse = (accept: boolean) => {
    return api.put('/seats/inviteResponse', undefined, {
        params: {
            accept,
        },
    });
};

export const getTeams = () => {
    return api.get<Team[]>('/teams');
};

export const createTeam = (team: Team) => {
    return api.post<Team>('/teams', team);
};

export const updateTeam = (team: Team) => {
    return api.put<Team>(`/teams/${team.id}`, team);
};

export const deleteTeam = (id: string) => {
    return api.delete(`/teams/${id}`);
};

export const super_getDocUrlAsFile = async (requestId: string) => {
    await noop(); //refreshes the access token if necessary
    const { Authorization } = api.defaults.headers.common;

    try {
        const res = await fetch(
            `${API_BASE_URL}/super/requests/${requestId}/document`,
            {
                headers: {
                    Authorization: Authorization as string,
                },
            }
        );

        if (!res.ok) throw new Error('File fetch error.');

        const blob = await res.blob();
        return new File([blob], 'document.pdf');
    } catch (error) {
        throw new Error('File fetch error.');
    }
};

export const getDistinctRecipients = () => {
    return api.get<{ emails: string[] }>('/recipients/distinct');
};

interface RejectParams {
    reason?: string;
    requestId: string;
}

export const rejectSignRequest = ({ requestId, reason }: RejectParams) => {
    return api.put(`/requests/${requestId}/reject`, { reason });
};

interface RejectNoAuthParams {
    reason?: string;
    token: string;
}

export const rejectSignRequestNoAuth = ({
    reason,
    token,
}: RejectNoAuthParams) => {
    return api.put(
        `/requests/reject/noauth`,
        { reason },
        { params: { token } }
    );
};

export const disableMfa = () => {
    return api.delete('/mfa');
};
