import { ReactComponent } from '@/assets/icons/pdf.svg';
import { CSSProperties } from 'react';

export interface UploadIconProps {
    color?: string;
    height?: number;
    style?: CSSProperties;
    className?: string;
    onClick?: () => void;
}

const Icon = ({ height = 20, style, className, onClick }: UploadIconProps) => {
    return (
        <ReactComponent
            height={height}
            width={height}
            style={style}
            className={className}
            onClick={onClick}
        />
    );
};
export default Icon;
