import { ReactComponent as Received } from '@/assets/icons/received.svg';

export interface ReceivedIconProps {
    color?: string;
    height?: number;
}

const ReceivedIcon = ({ color = 'black', height = 20 }: ReceivedIconProps) => {
    return <Received height={height} stroke={color} />;
};
export default ReceivedIcon;
