import { ReactComponent as Sent } from '@/assets/icons/sent.svg';

export interface SentIconProps {
    color?: string;
    height?: number;
}

const SentIcon = ({ color = 'black', height = 20 }: SentIconProps) => {
    return <Sent height={height} stroke={color} />;
};
export default SentIcon;
