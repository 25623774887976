import { ReactComponent } from '@/assets/icons/checkmark.svg';
import { CSSProperties } from 'react';

export interface UploadIconProps {
    color?: string;
    height?: number;
    style?: CSSProperties;
    className?: string;
    onClick?: () => void;
}

const Icon = ({
    color = '#131a8c',
    height = 20,
    style,
    className,
    onClick,
}: UploadIconProps) => {
    return (
        <ReactComponent
            height={height}
            stroke={color}
            style={{ minWidth: (height * 12) / 16, ...style }}
            className={className}
            onClick={onClick}
            // title="Checkmark"
        />
    );
};
export default Icon;
