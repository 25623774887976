import { ReactComponent } from '@/assets/icons/menu.svg';
import React from 'react';
import { CSSProperties } from 'react';

export interface UploadIconProps {
    color?: string;
    height?: number;
    style?: CSSProperties;
    className?: string;
    onClick?: () => void;
}

const Icon = (
    {
        color = 'black',
        height = 20,
        style,
        className,
        onClick,
    }: UploadIconProps,
    ref: any
) => {
    return (
        <ReactComponent
            ref={ref}
            height={height}
            // stroke={color}
            fill={color}
            style={style}
            className={className}
            onClick={onClick}
        />
    );
};
export default React.forwardRef(Icon);
