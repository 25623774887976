import styles from './FallbackError.module.css';

export interface FallbackErrorProps {}

const FallbackError = ({}: FallbackErrorProps) => {
    return (
        <div className={styles.container}>
            Oops there was an error! Please reload the page.
        </div>
    );
};
export default FallbackError;
